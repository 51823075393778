<template>
  <div>
    <h1>Newsletter</h1>
  </div>
</template>

<script>
import { scrollTop } from '@/scripts/helper.js'

export default {
  mounted: function() {
    document.title = 'Signup | Code with Aba';
    scrollTop();
  }
}
</script>

<style scoped>

</style>
